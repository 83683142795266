import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()
objectFitVideos()

import Accordions from './accordions'
import Gallery from './gallery'
import MediaKits from './media-kits'
import Menu from './menu'
import News from './news'
import Parallax from './parallax'
import Recipients from './recipients'
import Search from './search'
import { setSuperscript } from './superscript'
import Formbuilder from './formbuilder'
import Videos from './videos'

window.setSuperscript = setSuperscript
setSuperscript()

new Accordions()
new Gallery()
new MediaKits()
new Menu()
new News()
new Parallax()
new Recipients()
new Search()
new Formbuilder()
new Videos()
