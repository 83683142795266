export default class Parallax {
  constructor() {
    const items = Array.from(document.querySelectorAll('.parallax'))
    this.windowHeight = window.innerHeight

    items.forEach((item) => {
      const img = item.querySelector('img')

      if (img) {
        window.addEventListener('DOMContentLoaded', () => {
          this.setPosition(item, img)
        })

        window.addEventListener('resize', () => {
          this.windowHeight = window.innerHeight
          this.setPosition(item, img)
        })

        window.addEventListener('scroll', () => {
          this.setPosition(item, img)
        })
      }
    })

    const homeMedal = document.querySelector('.home-hero__medal')

    if (homeMedal) {
      window.addEventListener('DOMContentLoaded', () => {
        this.setMedalPosition(homeMedal)
      })

      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight
        this.setMedalPosition(homeMedal)
      })

      window.addEventListener('scroll', () => {
        this.setMedalPosition(homeMedal)
      })
    }
  }

  setPosition(item, img) {
    const bounds = item.getBoundingClientRect()
    let distance = 0

    if (bounds.bottom <= 0) {
      distance = 1
    } else if (bounds.bottom > 0 && bounds.top < this.windowHeight) {
      distance = 1 - bounds.bottom / (this.windowHeight + item.clientHeight)
    } else {
      distance = 0
    }

    const topDistance = -40 * distance
    img.style.top = `${topDistance}px`
  }

  setMedalPosition(item) {
    const bounds = item.getBoundingClientRect()
    const scrollDistance = window.scrollY
    const img = item.querySelector('img')
    let distance = 0

    if (scrollDistance === 0) {
      distance = 0
    } else if (scrollDistance < bounds.bottom + scrollDistance) {
      distance = window.scrollY / (window.scrollY + bounds.bottom)
    } else {
      distance = 1
    }

    const topDistance = -80 * distance
    img.style.top = `${topDistance}px`
  }
}
