import { setPagination } from './pagination'

export default class News {
  constructor() {
    this.newsPagination = document.querySelector('.news-posts__pagination')

    if (this.newsPagination) {
      this.type = ''
      this.pagination = document.getElementById('pagination')
      this.per = parseInt(this.pagination.dataset.per)
      this.total = parseInt(this.pagination.dataset.total)

      if (this.pagination.classList.contains('internal-pagination')) {
        this.type = 'internal'
      } else {
        this.type = 'external'
      }

      this.buttons = document.querySelectorAll('.news-posts__filters button')

      this.filters = {
        page: 1,
        category: '',
      }

      Array.from(this.buttons).forEach((button) => {
        button.addEventListener('click', (event) => {
          const target = event.target

          if (target.classList.contains('active')) {
            $(this.buttons).removeClass('active')
            this.filters.category = ''
          } else {
            $(this.buttons).removeClass('active')
            event.target.classList.add('active')

            const selection = event.target.textContent
            this.filters.category = selection
          }

          this.filterNews()
        })
      })

      this.prev = () => {
        this.filters.page -= 1
        this.changePage(this.filters.page)
      }

      this.next = () => {
        this.filters.page += 1
        this.changePage(this.filters.page)
      }

      this.toPage = (num) => {
        this.filters.page = num
        this.changePage(this.filters.page)
      }

      setPagination(
        this.pagination,
        this.filters.page,
        this.per,
        this.total,
        this.prev,
        this.next,
        this.toPage,
      )
    }
  }

  changePage(page) {
    this.filters.page = page

    let path = '/news/internal.js'

    if (this.type === 'external') {
      path = '/news/external.js'
    }

    $.ajax({
      url: path,
      method: 'GET',
      data: this.filters,
      success: () => {
        setPagination(
          this.pagination,
          this.filters.page,
          this.per,
          this.total,
          this.prev,
          this.next,
          this.toPage,
        )
      },
    })
  }

  filterNews() {
    this.filters.page = 1

    $.ajax({
      url: '/news/internal.js',
      method: 'GET',
      data: this.filters,
      success: () => {
        this.total = parseInt(this.pagination.dataset.total)

        setPagination(
          this.pagination,
          this.filters.page,
          this.per,
          this.total,
          this.prev,
          this.next,
          this.toPage,
        )
      },
    })
  }
}
