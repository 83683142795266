export default class Menu {
  constructor() {
    this.hamburger = document.querySelector('.hamburger__wrapper')
    this.nav = document.querySelector('.header__nav')
    this.searchButton = document.querySelector('.header__search-button')
    this.searchForm = document.querySelector('.header form')
    this.childrenToggles = document.querySelectorAll('.children-toggle')

    this.initMenu()
  }

  initMenu() {
    this.searchButton.addEventListener('click', () => {
      this.searchForm.classList.add('open')
      this.searchForm.querySelector('input[type="text"]').focus()
    })

    var ignoreClickOnMeElement = this.searchForm

    document.addEventListener('click', (event) => {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target)
      if (
        !isClickInsideElement &&
        this.searchForm.querySelector('input[type="text"]') !==
          document.activeElement
      ) {
        this.searchForm.classList.remove('open')
      }
    })

    this.hamburger.addEventListener('click', () => {
      this.hamburger.classList.toggle('open')
      $(this.nav).slideToggle(300)
    })

    Array.from(this.childrenToggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle('open')
        $(toggle).next().slideToggle(300)
      })
    })
  }
}
