export const setSuperscript = () => {
  const text = Array.from(
    document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, li, a, span'),
  )

  text.forEach((item) => {
    const regexp = /(\d+)(th|rd|st|nd)/g
    let html = item.innerHTML
    if (html.match(regexp)) {
      const matches = [...html.matchAll(regexp)]

      matches.forEach((group) => {
        html = html.replace(group[0], `${group[1]}<sup>${group[2]}</sup>`)
      })

      item.innerHTML = html
    }
  })
}
