import { setPagination } from './pagination'

export default class Search {
  constructor() {
    this.list = document.querySelector('.search-results__list')

    if (this.list) {
      this.page = 1
      this.pagination = document.getElementById('pagination')
      this.per = parseInt(this.pagination.dataset.per)
      this.total = parseInt(this.pagination.dataset.total)
      this.q = this.list.dataset.query

      this.prev = () => {
        this.page -= 1
        this.changePage(this.page)
      }

      this.next = () => {
        this.page += 1
        this.changePage(this.page)
      }

      this.toPage = (num) => {
        this.page = num
        this.changePage(this.page)
      }

      setPagination(
        this.pagination,
        this.page,
        this.per,
        this.total,
        this.prev,
        this.next,
        this.toPage,
      )
    }
  }

  changePage(page) {
    $.ajax({
      url: '/search.js',
      method: 'GET',
      data: {
        page: page,
        q: this.q,
      },
      success: () => {
        setPagination(
          this.pagination,
          this.page,
          this.per,
          this.total,
          this.prev,
          this.next,
          this.toPage,
        )
      },
      error: (e) => {
        console.log(e)
      },
    })
  }
}
