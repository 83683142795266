import debounce from 'lodash.debounce'
import qs from 'qs'
import { setPagination } from './pagination'

export default class Recipients {
  constructor() {
    this.form = document.getElementById('recipients-form')

    if (this.form) {
      this.toggle = document.getElementById('filters')
      this.pagination = document.getElementById('pagination')
      this.per = parseInt(this.pagination.dataset.per)
      this.total = parseInt(this.pagination.dataset.total)

      this.filters = {
        year: '',
        category: '',
        q: '',
        page: 1,
      }

      const params = qs.parse(location.search.replace('?', ''))
      if (params.category) {
        this.filters.category = params.category
      }

      this.form
        .querySelector('#year')
        .addEventListener('change', (event) => this.filterRecipients(event))

      this.form
        .querySelector('#category')
        .addEventListener('change', (event) => this.filterRecipients(event))

      this.form.querySelector('#q').addEventListener(
        'keyup',
        debounce((event) => {
          this.filterRecipients(event)
        }, 500),
      )

      this.toggle.addEventListener('click', () => {
        if (this.toggle.classList.contains('open')) {
          this.toggle.textContent = 'Show Filters'
          this.toggle.classList.remove('open')
          $(this.form).slideUp(300)
        } else {
          this.toggle.textContent = 'Hide Filters'
          this.toggle.classList.add('open')
          $(this.form).slideDown(300)
        }
      })

      this.prev = () => {
        this.filters.page -= 1
        this.changePage(this.filters.page)
      }

      this.next = () => {
        this.filters.page += 1
        this.changePage(this.filters.page)
      }

      this.toPage = (num) => {
        this.filters.page = num
        this.changePage(this.filters.page)
      }

      setPagination(
        this.pagination,
        this.filters.page,
        this.per,
        this.total,
        this.prev,
        this.next,
        this.toPage,
      )
    }
  }

  filterRecipients(event) {
    const field = event.target
    const value = field.value

    this.filters.page = 1
    this.filters[field.id] = value ? value : ''

    $.ajax({
      url: '/recipients.js',
      method: 'GET',
      data: this.filters,
      success: () => {
        this.total = parseInt(this.pagination.dataset.total)

        setPagination(
          this.pagination,
          this.filters.page,
          this.per,
          this.total,
          this.prev,
          this.next,
          this.toPage,
        )
      },
    })
  }

  changePage(page) {
    this.filters.page = page

    $.ajax({
      url: '/recipients.js',
      method: 'GET',
      data: this.filters,
      success: () => {
        setPagination(
          this.pagination,
          this.filters.page,
          this.per,
          this.total,
          this.prev,
          this.next,
          this.toPage,
        )
      },
      error: (e) => {
        console.log(e)
      },
    })
  }
}
