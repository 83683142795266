import Vimeo from '@vimeo/player'

export default class Videos {
  constructor() {
    if ($('.videos').length > 0 || $('.has-video').length > 0) {
      let tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      let firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = () => {
        $('.video').each((_, video) => {
          const $video = $(video)

          if ($video.find('video').length > 0) {
            $video.find('.video__play, .video__cover').on('click', () => {
              $video.find('.video__file, .video__file__backdrop').fadeIn(300)
              this.playVideo($video)
            })

            $video
              .find('.video__file__close, .video__file__backdrop')
              .on('click', () => {
                const file = $video.find('video')
                file[0].stop()
                $video.find('.video__file, .video__file__backdrop').fadeOut(300)
              })
          } else if ($video.find('iframe').attr('src').includes('youtube')) {
            new YT.Player($video.find('iframe').attr('id'), {
              events: {
                onReady: (event) => {
                  $video.find('.video__play, .video__cover').on('click', () => {
                    $video
                      .find('.video__file, .video__file__backdrop')
                      .fadeIn(300)
                  })

                  $video
                    .find('.video__file__close, .video__file__backdrop')
                    .on('click', () => {
                      event.target.stopVideo()

                      $video
                        .find('.video__file, .video__file__backdrop')
                        .fadeOut(300)
                    })
                },
              },
            })
          } else {
            const vimeoVideo = new Vimeo($video.find('iframe').attr('id'))

            $video.find('.video__play, .video__cover').on('click', () => {
              $video.find('.video__file, .video__file__backdrop').fadeIn(300)

              vimeoVideo.play()
            })

            $video
              .find('.video__file__close, .video__file__backdrop')
              .on('click', () => {
                vimeoVideo.stop()

                $video.find('.video__file, .video__file__backdrop').fadeOut(300)
              })
          }
        })
      }
    }
  }

  playVideo(video) {
    $video.find('.video__file, .video__file__backdrop').fadeIn(300)

    const file = video.find('video')
    file[0].play()
  }
}
