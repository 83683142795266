export default class Accordions {
  constructor() {
    $('.accordion-toggle').on('click', (event) => {
      const target = $(event.currentTarget)
      target.toggleClass('open')
      target.next().toggleClass('open').slideToggle(400)

      this.setExpandButton(target)
    })

    $('.accordions__expand').on('click', (event) => {
      const target = $(event.currentTarget)
      this.toggleAccordions(target)
    })
  }

  setExpandButton(target) {
    const parent = target.parent().parent()

    if (parent.find('.accordions__expand').length > 0) {
      const expand = parent.find('.accordions__expand')
      const children = parent.find('.accordion-content').length
      const openChildren = parent.find('.accordion-content.open').length

      if (children === openChildren) {
        expand.text('Collapse all')
      } else {
        expand.text('Expand all')
      }
    }
  }

  toggleAccordions(target) {
    const parent = target.parent()
    const titles = parent.find('.accordion-toggle')
    const children = parent.find('.accordion-content')
    const openChildren = parent.find('.accordion-content.open').length

    if (children.length === openChildren) {
      titles.removeClass('open')
      children.removeClass('open').slideUp(400)
    } else {
      titles.addClass('open')
      children.addClass('open').slideDown(400)
    }

    this.setExpandButton(target)
  }
}
