export default class Gallery {
  constructor() {
    $('.gallery__slides').each((_, items) => {
      $(items).on('init', () => {
        this.setGalleryHeight()
      })

      $(items).slick({
        variableWidth: true,
      })
    })

    $(window).on('resize', () => {
      this.setGalleryHeight()
    })
  }

  setGalleryHeight() {
    $('.gallery .slick-track').height('auto')
    $('.gallery .slick-track').height($('.gallery .slick-current').height())
  }
}
