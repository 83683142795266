export default class MediaKits {
  constructor() {
    this.form = document.getElementById('media-kits-form')

    if (this.form) {
      this.toggle = document.getElementById('filters')

      this.filters = {
        year: '',
      }

      this.form
        .querySelector('#year')
        .addEventListener('change', (event) => this.filterKits(event))

      this.toggle.addEventListener('click', () => {
        if (this.toggle.classList.contains('open')) {
          this.toggle.textContent = 'Show Filters'
          this.toggle.classList.remove('open')
          $(this.form).slideUp(300)
        } else {
          this.toggle.textContent = 'Hide Filters'
          this.toggle.classList.add('open')
          $(this.form).slideDown(300)
        }
      })
    }
  }

  filterKits(event) {
    const field = event.target
    const value = field.value

    this.filters[field.id] = value ? value : ''

    $.ajax({
      url: '/media-kits.js',
      method: 'GET',
      data: this.filters,
    })
  }
}
