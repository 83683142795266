import createPagination from 'giant-piano'

export const setPagination = (el, page, per, total, prev, next, toPage) => {
  if (el) {
    const paginate = createPagination({
      itemsPerPage: per,
      maxPages: 5,
    })

    const settings = paginate({
      currentPage: page,
      totalItems: total === 0 ? 1 : total,
    })

    let template = `
      <div class="pagination__inner">
        <button class="page-arrow page-arrow--prev ${
          page === 1 ? 'page-arrow--disabled' : ''
        }">
          Previous Page
          <span></span>
        </button>
    `

    if (settings.pages[0] === 2) {
      template += `
        <button class="page page--first">
          <span>1</span>
        </button>
      `
    } else if (settings.pages[0] !== 1) {
      template += `
        <button class="page page--first">
          <span>1</span>
        </button>
        <span class="filler">…</span>
      `
    }

    settings.pages.forEach((num) => {
      template += `
      <button class="page ${page === num ? 'page--current' : ''}">
        <span>${num}</span>
      </button>
    `
    })

    const lastVisible = settings.pages[settings.pages.length - 1]

    if (lastVisible === settings.lastPage - 1) {
      template += `
        <button class="page page--last">
          <span>${settings.lastPage}</span>
        </button>
      `
    } else if (lastVisible !== settings.lastPage) {
      template += `
        <span class="filler">…</span>
        <button class="page page--last">
          <span>${settings.lastPage}</span>
        </button>
      `
    }

    template += `
        <button class="page-arrow page-arrow--next  ${
          page === settings.lastPage ? 'page-arrow--disabled' : ''
        }">
          Next Page
          <span></span>
        </button>
      </div>
    `

    el.innerHTML = template

    el.querySelector('.page-arrow--prev').addEventListener('click', () =>
      prev(),
    )
    el.querySelector('.page-arrow--next').addEventListener('click', () =>
      next(),
    )

    const numbers = Array.from(el.querySelectorAll('.page:not(.page--current)'))
    numbers.forEach((item) => {
      item.addEventListener('click', (event) => {
        const text = event.target.textContent
        toPage(parseInt(text))
      })
    })
  }
}
